<template>
  <b-modal
    class="bmodal"
    size="sm"
    id="newServiceModal"
    ref="modalNewService"
    :title="translations['add-service-time'].tcNewServiceTime"
    @ok="handleOkClick($event)"
    :ok-disabled="notReadyToSave"
    :no-close-on-backdrop="noCloseOnBackdrop"
    :ok-title="translations['add-service-time'].tcOk"
    :cancel-title="translations['add-service-time'].tcCancel"
  >
    <p>
      <b>{{ translations['add-service-time'].tcAddNewChurchServiceTime }} {{ church_name }}</b>
    </p>
    <br />
    <div class="formdiv">
      <b-form>
        <b-row>
          <b-col sm="12" class="font-style-3">
            {{ translations['add-service-time'].tcServiceType }}
            <b-form-select class="select_caps" v-model="selected_service_type" :options="churchServiceTypesTranslated">
              <template slot="first">
                <option :value="null" disabled>
                  {{ translations['add-service-time'].tcSelectServiceType }}
                </option>
              </template>
            </b-form-select>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" class="font-style-3">
            {{ translations['add-service-time'].tcDay }}
            <b-form-select class="select_caps" v-model="selected_week_day" :options="daysTranslated">
              <template slot="first">
                <option :value="null" disabled>
                  {{ translations['add-service-time'].tcSelectDay }}
                </option>
              </template>
            </b-form-select>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" class="font-style-3">
            {{ translations['add-service-time'].tcServiceTime }}
            <br />
            <b-form-timepicker
              v-model="selected_service_time"
              minutes-step="5"
              :locale="prefCulture"
              :label-close-button="translations['add-service-time'].tcClose"
              :label-no-time-selected="translations['add-service-time'].tcNoTimeSelected"
              >
            </b-form-timepicker>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" class="font-style-3">
            {{ translations['add-service-time'].tcAttire }}
            <b-form-select class="select_caps" v-model="selected_attire" :options="attire">
              <template slot="first">
                <option :value="null" disabled>
                  {{ translations['add-service-time'].tcSelectAttire }}
                </option>
              </template>
            </b-form-select>
          </b-col>
        </b-row>
      </b-form>
      <p class="note">* {{ translations['add-service-time'].tcAllFieldsRequired }}</p>
      <p class="upper">{{ selectionResults }}</p>
    </div>
  </b-modal>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions } from 'vuex'
import dateData from '@/json/date.json'
import { numericMixin } from '@/mixins/numericMixin'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'add-service-time',
  props: {
    church_key: { type: String, default: null },
    church_name: { type: String, default: '' },
    service_being_edited: { type: Object, default: null },
  },
  watch: {
    service_being_edited: {
      handler(value, oldvalue) {
        this.buildServiceToEdit(value)
      },
      deep: true,
    },
  },
  mixins: [numericMixin, translationMixin],
  data() {
    return {
      translations: { 'add-service-time': {} },
      selected_attire: null,
      selected_service_time: null,
      selected_service_type: null,
      selected_week_day: null,
      noCloseOnBackdrop: true,
      new_service_time: {
        cti_key: null,
        cti_org_key: null,
        cti_cst_key: null,
        cti_cat_key: null,
        cti_wkd_key: null,
        cti_service_time: { hh: null, mm: null, A: null },
        cti_nag_key: null,
        cti_add_user: null,
        cti_add_date: null,
        cti_change_user: null,
        cti_change_date: null,
        cti_delete_flag: 0,
      },
      churchServiceTypes: [
        { value: '989bb4f1-b4a3-4fce-9f60-0b32073a6ddd', text: 'Worship AM' },
        { value: '3deaa9e8-0921-4ca1-87b3-2acad1924c1a', text: 'Worship PM' },
        { value: '69164a7c-6660-4b14-8b79-7eab1c6eb471', text: 'Other' },
        { value: '1921ff64-132a-4d34-b822-83169646b5ab', text: 'Bible Study' },
        { value: '86e1d240-05de-4af2-a0bf-b42ac80d9602', text: 'Mid-Week Service' },
        { value: '89f5057e-6587-484a-b6ec-c7ebf0369d15', text: 'Sunday School' },
      ],
      churchServiceTypesTranslated: {},
      attire: [
        { value: 'ecc354a3-1e43-4af1-acb6-bd02ee729535', text: 'Traditional' },
        { value: '68ec046b-2853-4d50-b49e-cfe9b69216dd', text: 'Business Casual' },
      ],
      attireTranslated: {},
      days: [],
      daysTranslated: [],
    }
  },
  computed: {
    ...mapGetters({
      prefCulture: 'user/userPreferredCulture',
      userId: 'user/userId',
    }),
    notReadyToSave: function () {
      const serviceType = this.churchServiceTypes.find((r) => r.value === this.selected_service_type)
      const day = !this.selected_week_day
        ? null
        : this.daysTranslated.find((r) => {
            return r.value === this.selected_week_day
          })
      const time = this.formatTimeString(this.selected_service_time)
      return !serviceType || !day || !time
    },
    selectionResults() {
      let tp = '',
        tm = '',
        st = ''
      try {
        tp = this.churchServiceTypes.find((r) => r.value === this.selected_service_type).text
      } catch (e) {
        tp = ''
      }
      try {
        tm = this.daysTranslated.find((r) => r.value === this.selected_week_day).text
      } catch (e) {
        tm = ''
      }
      try {
        st = this.formatTimeString(this.selected_service_time)
      } catch (e) {
        st = ''
      }
      return tp + ' ' + tm + ' ' + st
    },
  },
  methods: {
    ...mapActions({
      saveNewChurchServiceTime: 'churchMinistry/saveNewChurchServiceTime',
    }),
    closeModal() {
      this.selected_attire = null
      this.selected_service_time = null
      this.selected_service_type = null
      this.selected_week_day = null
    },
    async buildServiceToEdit(value) {
      if (value !== null) {
        this.selected_service_type = this.churchServiceTypes.find((st) => st.value === value.cst_key).value
        this.selected_service_time = this.formatTimeToMilitary(value.service_time)
        this.selected_week_day = this.daysTranslated.find(
          (wd) => wd.value.toLowerCase() === value.weekday_key.toLowerCase()
        ).value
        this.selected_attire = this.attire.find((sa) => sa.value === value.attire_key).value
      } else {
        this.selected_attire = null
        this.selected_service_time = null
        this.selected_service_type = null
        this.selected_week_day = null
      }
    },
    async handleOkClick(evt) {
      this.new_service_time = {
        cti_key: this.service_being_edited === null ? null : this.service_being_edited.cti_key,
        cti_org_key: this.church_key,
        cti_cst_key: this.selected_service_type,
        cti_cat_key: this.selected_attire,
        cti_wkd_key: this.selected_week_day,
        cti_service_time: this.formatTimeString(this.selected_service_time),
        cti_add_user: this.service_being_edited === null ? this.userId : null,
        cti_add_date: this.service_being_edited === null ? new Date().toISOString() : null,
        cti_change_user: this.service_being_edited === null ? null : this.userId,
        cti_change_date: this.service_being_edited === null ? null : new Date().toISOString(),
        cti_delete_flag: 0,
      }
      if (await this.saveNewChurchServiceTime(this.new_service_time)) {
        this.$emit('timeAddSuccess', true)
      } else {
        this.$emit('timeAddSuccess', false)
      }
      this.closeModal()
    },
  },
  components: {
  },
  async created() {
    try {
      this.days = dateData.days // needed for this.translateDropdownDaysOfWeek() call
      await Promise.all([
        await this.getComponentTranslations('add-service-time'),
        await this.getComponentTranslations('common.attire', 'common.church-service-type', 'common.days'),
      ]).then((results) => {
        const componentTranslations = results[0]
        this.$set(this, 'translations', componentTranslations)
        this.stripReadableText(results[1])
        this.translateDropdownOptions([
          { node: 'attire', store: 'attire' },
          { node: 'church-service-type', store: 'churchServiceTypes' },
        ])
        this.translateDropdownDaysOfWeek('daysTranslated')
      })
    } catch (e) {
      console.error(e)
    }
  },
}
</script>

<style scoped>
.radioLabel {
  margin-left: 10px;
}
.select_caps {
  text-transform: uppercase;
}
.note {
  color: red;
}
.resultDiv {
  cursor: pointer;
}
.resultDiv:hover {
  background-color: gainsboro;
}
.lh42 {
  margin-bottom: 6px;
}
.upper {
  text-transform: uppercase;
  margin-top: 30px;
}
.font-style-3 {
  font-weight: 600;
}
</style>
